.BritanniaHomeTeam {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    background-color: #c91f44;
}

.BritanniaHomeTeam .TeamTitle {
    color: #eef1ef;
    margin-bottom: 1.5rem;
}

.BritanniaHomeTeam .Title {
    font-weight: bold;
    font-size: larger;
    margin-top: 0.5rem;
}

.BritanniaHomeTeam .Description {
    text-align: left;
    font-weight: bold;
}