.MasterFaq {
    padding-top: 1.5rem;
    background-color: #eef1ef;
    padding-bottom: 2rem;
}

.MasterFaq .Title {
    text-align: right;
}

.MasterFaq .Title .English {
    margin-top: 2rem;
    color: #c91f44;
    text-align: right;
}

.MasterFaq .Title .Chinese{
    margin-top: 0;
    color: #c91f44;
    text-align: right;
    padding-bottom: 0.7rem;
    border-bottom: #c91f44 1px solid;
}