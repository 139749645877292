.Contact {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #eef1ef;
}

.Contact .Title {
    text-align: center;
    color: #c91f44;
    font-weight: bolder;
    font-size: 25px;
}

.Contact .Info {
    text-align: left;
    font-weight: bolder;
}

.Contact .Info p {
    margin-bottom: 5px;
}

.Contact .Info .QRCode {
    margin-top: 0.8rem;
}

.Contact .Info .BottomLine {
    border-bottom: crimson solid 0.7px;
}

.Contact .Info .QRImage {
    margin-top: 0.5rem;
    width: 285px;
    height: 338px;
    border-radius: 5px;
}