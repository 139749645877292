.PhdFaqItem {
    margin-top: 1rem;
    text-align: right;
}

.PhdFaqItem .Answer {
    text-align: left;
}

.PhdFaqItem .Answer .Description{
    text-align: left;
    background-color: #c91f44;
    color: #eef1ef;
    font-weight: bolder;
    border-radius: 7px;
    padding: 0.5rem 0.9rem;
}

@media only screen and (max-width: 576px) {
    .PhdFaqItem {
        text-align: left;
    }
}