.Phd {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #eef1ef;
}

.Phd .Title {
    text-align: center;
    color: #c91f44;
    font-weight: bolder;
    font-size: 25px;
}

.Phd .Steps {
    margin-top: 2rem;
}

.Phd .Step {
    padding-top: 1rem;
    text-align: center;
    color: cornsilk;
    background-color: #012169;
    font-weight: bolder;
    border-radius: 10px;
}

.Phd .Arrow {
    padding-top: .5rem;
    text-align: center;
    font-size: 2rem;
    color: #c91f44;
}

.Phd .List {
    text-align: left;
    padding-top: 1.5rem;
}

.Phd .List .ListTitle {
    color: #c91f44;
    font-weight: bolder;
    font-size: larger;
}

.Phd .List .Avatar {
    color: #c91f44;
    font-weight: bolder;
    font-size: larger;
    line-height: 1.5rem;
}

.Phd .Divider {
    background-color: #c91f44;
}